import request from "@/utils/request";

// 获取消息列表
export function getMessage(params) {
  return request({
    url: `/seller/shops/shop-notice-logs`,
    method: 'get',
    loading: false,
    params
  })
}

// 删除消息列表
export function removeMessage(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}`,
    method: 'delete',
    loading: false
  })
}

// 获取消息列表
export function editMessage(ids) {
  return request({
    url: `/seller/shops/shop-notice-logs/${ids}/read`,
    method: 'put',
    loading: false
  })
}
// 获取企业公告列表
export function enterpriseNotice(params) {
  return request({
    url: `/seller/shops/enterpriseNotice`,
    method: 'get',
    loading: false,
    params
  })
}
//添加公告
export function addEnterpriseNotice(data) {
  return request({
    url: `/seller/shops/enterpriseNotice/add`,
    method: 'post',
    data: data,
    headers: {'Content-Type': 'application/json'},
    loading: false
  })
}
//编辑公告
export function editEnterpriseNotice(data) {
  return request({
    url: `/seller/shops/enterpriseNotice/edit/${data.id}`,
    method: 'post',
    headers: {'Content-Type': 'application/json'},
    params: data,
    loading: false
  })
}
//删除公告
export function delEnterpriseNotice(id) {
  return request({
    url: `/seller/shops/enterpriseNotice/${id}`,
    method: 'delete',
    loading: false
  })
}
//查看公告详情
export function infoEnterpriseNotice(id) {
  return request({
    url: `/seller/shops/enterpriseNotice/detail/${id}`,
    method: 'get',
    loading: false
  })
}